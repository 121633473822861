import { List_All_Banners } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllBanners = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      RouterList: [],
      GetAllBannersList: [],
      GetAllBannersListFilter: [],
    };
  },
  methods: {
    async GetAllBannersMethod(banner_status) {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(List_All_Banners, {
            input: {
              banner_status: banner_status,
            },
          })
        );
        this.GetAllBannersList = JSON.parse(result.data.List_All_Banners).data.items;
        this.GetAllBannersListFilter = JSON.parse(result.data.List_All_Banners).data.items;
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.GetAllBannersListFilter = [];
        this.GetAllBannersList = [];
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
