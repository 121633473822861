<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent width="400px" v-model="AddBannerdialog">
      <v-card class="overflow-hidden">
        <v-toolbar class="primary elevation-0" dense dark>
          <v-toolbar-title class="ml-2 white--text">{{
            StoreObj.action == "CREATE" ? "Add Banners" : "Edit Banners"
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            large
            class="mr-n4"
            dark
            text
            @click="AddBannerdialogEmit((Toggle = 1))"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4 pb-0">
          <v-form ref="form">
            <v-file-input
              accept="image/*"
              class="FontSize"
              append-icon="mdi-paperclip"
              v-model="files"
              label="File input"
              outlined
              dense
              :prepend-icon="null"
            ></v-file-input>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            small
            :loading="isLoading"
            class="mr-2 white--text primary"
            @click="validateMethod()"
            >ADD</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { API, graphqlOperation } from "aws-amplify";
import { UploadBannersToS3 } from "@/graphql/mutations.js";
import Snackbar from "@/components/Extras/Snackbar.vue";
var aws = require("aws-sdk");
export default {
  props: {
    StoreObj: Object,
    AddBannerdialog: Boolean,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    files: null,
    isLoading: false,
    SnackBarComponent: {},
  }),
  mounted() {},
  watch: {},
  methods: {
    validateMethod() {
      if (this.files != null) {
        this.uploadMediaToS3();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fill all the required details",
        };
      }
    },
    async createBanner(url) {
      try {
        var inputParams = {
          creator_user_id: this.$store.getters.get_current_user_details.user_id,
          banner_name: this.files.name,
          banner_file_type: this.files.type,
          banner_file_size: this.files.size,
          banner_url: url,
        };
        let result = await API.graphql(
          graphqlOperation(UploadBannersToS3, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.UploadBannersToS3);
        if (ResultObject.status == "SUCCESS") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.AddBannerdialogEmit((this.Toggle = 2));
        }
        this.isLoading = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.isLoading = false;
      }
    },
    async uploadMediaToS3() {
      this.isLoading = true;
      this.url = URL.createObjectURL(this.files);
      if (this.files.length != 0) {
        const fr = new FileReader();
        fr.readAsDataURL(this.files);
        fr.addEventListener("load", async () => {
          var imageUrl = [];
          imageUrl = fr.result.replace(
            "data:" + this.files.type + ";base64,",
            ""
          );
          var keyValue = `Banners/${this.files.name}`;
          var params = {
            Bucket: "servicewrkcabinet",
            Key: keyValue,
            Body: Buffer.from(imageUrl, "base64"),
            ContentType: this.files.type,
            ACL: "public-read",
          };
          var s3Client = new aws.S3({
            region: "ap-south-1",
            accessKeyId: "AKIARAJXABES6RGOPDVD",
            secretAccessKey: "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",
          });
          var self = this;
          s3Client.putObject(params, function (err) {
            if (err) {
              console.log(err);
              return null;
            } else {
              let url =
                "https://servicewrkcabinet.s3.ap-south-1.amazonaws.com" +
                "/" +
                keyValue.trim();
              self.createBanner(url);
            }
          });
        });
      } else {
        return null;
      }
    },
    AddBannerdialogEmit(Toggle) {
      this.files = null;
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
