<template>
  <div>
    <Overlay :overlay="overlay" />
    <AddServiceType
      :StoreObj="StoreObj"
      :AddServiceTypedialog="AddServiceTypedialog"
      :GetAllManufacturerList="GetAllManufacturerList"
      @clicked="AddServiceTypedialogEmit"
    />
    <AddBanners
      :StoreObj="StoreObj"
      :AddBannerdialog="AddBannerdialog"
      @clicked="AddBannerdialogEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="6" xs="12">
          <v-toolbar dense class="elevation-0">
            <div class="PrimaryFontColor heading1">
              Master Settings - ({{ master_setting_type }})
            </div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" sm="12" md="6" xs="12">
          <v-toolbar dense class="elevation-0">
            <v-spacer></v-spacer>
            <v-select
              dense
              outlined
              v-if="master_setting_type == 'Service Types'"
              class="FontSize maxWidthSmall field_label_size field_height mt-9 mr-2"
              label="Status"
              v-model="status"
              :items="statusItems"
            ></v-select>
            <v-select
              dense
              outlined
              v-if="master_setting_type == 'Service Types'"
              class="FontSize maxWidth field_label_size field_height mt-9"
              label="Select Organization"
              v-model="organization_id"
              :items="GetAllManufacturerList"
              item-text="organization_name"
              item-value="organization_id"
            ></v-select>
            <v-tooltip color="primary" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  @click="
                    master_setting_type == 'Service Types'
                      ? (AddServiceTypedialog = true)
                      : (AddBannerdialog = true),
                      (StoreObj.action = 'CREATE')
                  "
                  v-on="on"
                  v-bind="attrs"
                  class="ml-3 mt-3 primary white--text"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </template>
              <span
                class="white--text"
                v-text="
                  master_setting_type == 'Service Types'
                    ? 'Add Service Type'
                    : 'Add Banner'
                "
              ></span>
            </v-tooltip>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-data-table
          dense
          :no-data-text="noDataText"
          :headers="
            master_setting_type == 'Service Types'
              ? serviceTypeHeaders
              : bannerHeaders
          "
          :items="
            master_setting_type == 'Service Types'
              ? GetAllServiceTypeList
              : GetAllBannersList
          "
           :footer-props="{
            'items-per-page-options': [100, 200, 500],
          }"
          :items-per-page="100"
          fixed-header
          :height="TableHeight"
        >
          <template v-slot:[`item.banner_url`]="{ item }">
            <span class="FontSize"
              ><v-card max-width="100px" class="elevation-0">
                <v-img :src="item.banner_url"></v-img></v-card
            ></span>
          </template>
          <template v-slot:[`item.service_type_name`]="{ item }">
            <span class="FontSize">{{ item.service_type_name }}</span>
          </template>
          <template v-slot:[`item.organization_name`]="{ item }">
            <span class="FontSize">{{ item.organization_name }}</span>
          </template>
          <template v-slot:[`item.banner_name`]="{ item }">
            <span class="FontSize">{{ item.banner_name }}</span>
          </template>
          <template v-slot:[`item.banner_file_type`]="{ item }">
            <span class="FontSize">{{ item.banner_file_type }}</span>
          </template>
          <template v-slot:[`item.banner_file_size`]="{ item }">
            <span class="FontSize">{{ item.banner_file_size }}</span>
          </template>
          <template v-slot:[`item.ActionsS`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  color="primary"
                  @click="checkItem(item, 'EDIT')"
                  v-if="item.service_type_status == 'ACTIVE'"
                  small
                  v-on="on"
                  class="mr-3"
                >
                  mdi-pencil-plus
                </v-icon>
              </template>
              <span class="white--text">Update Service Type Details</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import AddBanners from "@/components/MasterSettings/Dialogs/AddBanners.vue";
import AddServiceType from "@/components/MasterSettings/Dialogs/AddServiceType.vue";
import { GetAllBanners } from "@/mixins/GetAllBanners.js";
import { GetAllManufacturer } from "@/mixins/GetAllManufacturer.js";
import { GetAllServiceTypes } from "@/mixins/GetAllServiceTypes.js";
export default {
  props: {
    master_setting_type: String,
  },
  components: {
    Overlay,
    AddBanners,
    AddServiceType,
  },
  mixins: [GetAllManufacturer, GetAllServiceTypes, GetAllBanners],
  data() {
    return {
      StoreObj: {},
      TableHeight : 0,
      status: "ACTIVE",
      organization_id: null,
      AddBannerdialog: false,
      AddServiceTypedialog: false,
      GetAllBannersList: [],
      GetAllServiceTypeList: [],
      GetAllManufacturerList: [],
      GetAllBannersListFilter: [],
      GetAllServiceTypeListFilter: [],
      statusItems: ["ACTIVE", "INACTIVE"],
      items: [
        {
          title: "Service Type",
          link: "/ServiceType",
        },
        {
          title: "Banners",
          link: "/Banner",
        },
      ],
      serviceTypeHeaders: [
        { text: "Service Type", value: "service_type_name" },
        { text: "Manufacturer", value: "organization_name" },
        { text: "Actions", value: "ActionsS" },
      ],
      bannerHeaders: [
        { text: "Banner", value: "banner_url" },
        { text: "Name", value: "banner_name" },
        { text: "File Type", value: "banner_file_type" },
        { text: "File Size", value: "banner_file_size" },
      ],
    };
  },
  watch: {
    status() {
      this.GetAllServiceTypeMethod(this.organization_id, this.status);
    },
    organization_id() {
      this.GetAllServiceTypeMethod(this.organization_id, this.status);
    },
    master_setting_type: {
      immediate: true,
      handler(value) {
        if (value == "Service Types") {
          this.GetAllServiceTypeMethod(this.organization_id, this.status);
        } else if (value == "Banners") {
          this.GetAllBannersMethod();
        }
      },
    },
  },
  mounted() {
    this.GetAllBMSMenufacturerMethod();
    this.TableHeight = window.innerHeight - 200;
  },
  methods: {
    checkItem(item, action) {
      this.StoreObj = item;
      this.StoreObj.action = action;
      this.AddServiceTypedialog = true;
    },
    AddBannerdialogEmit(Toggle) {
      this.AddBannerdialog = false;
      if (Toggle == 2) {
        this.GetAllBannersMethod();
      }
    },
    AddServiceTypedialogEmit(Toggle) {
      this.AddServiceTypedialog = false;
      if (Toggle == 2) {
        this.GetAllServiceTypeMethod(this.organization_id, this.status);
      }
    },
  },
};
</script>
