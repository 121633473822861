<template>
  <div>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent width="400px" v-model="AddServiceTypedialog">
      <v-card>
        <v-toolbar class="primary elevation-0" dense dark>
          <v-toolbar-title class="ml-2 white--text">{{
            StoreObj.action == "CREATE"
              ? "Add Service Type"
              : "Edit Service Type"
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            large
            class="mr-n4"
            dark
            text
            @click="AddServiceTypedialogEmit((Toggle = 1))"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4 pb-0">
          <v-form ref="form">
            <v-autocomplete
              item-text="organization_name"
              item-value="organization_id"
              :items="
                GetAllManufacturerList.filter(
                  (item) => item.organization_name != 'ALL'
                )
              "
              outlined
              dense
              :readonly="StoreObj.action == 'EDIT'"
              label="Manufacturer *"
              class="FontSize field_label_size field_height"
              v-model="manufacturer"
              :rules="[(v) => !!v || 'Required']"
            >
            </v-autocomplete>
            <v-text-field
              outlined
              dense
              label="Service Type *"
              :rules="[(v) => !!v || 'Required']"
              class="FontSize field_label_size field_height"
              v-model="serviceType_name"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            small
            :loading="isLoading"
            @click="validateMethod"
            class="mr-2 primary white--text"
            ><span v-text="StoreObj.action == 'CREATE' ? 'ADD' : 'EDIT'"></span
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { API, graphqlOperation } from "aws-amplify";
import { CreateServiceType, UpdateServiceType } from "@/graphql/mutations.js";
import SnackBar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    StoreObj: Object,
    AddServiceTypedialog: Boolean,
    GetAllManufacturerList: Array,
  },
  components: {
    SnackBar,
  },
  data: () => ({
    Toggle: 0,
    org_ticket: "",
    manufacturer: "",
    Master_Category: "",
    serviceType_name: "",
    isLoading: false,
    manufacturers: [],
    SnackBarComponent: {},
  }),
  watch: {
    AddServiceTypedialog(val) {
      if (val == true) {
        this.manufacturer = this.StoreObj.organization_id;
        this.serviceType_name = this.StoreObj.service_type_name;
      }
    },
  },
  methods: {
    async editServiceType() {
      this.isLoading = true;
      try {
        var inputParams = {
          updater_user_id: this.$store.getters.get_current_user_details.user_id,
          service_type_id: this.StoreObj.service_type_id,
          service_type_name: this.serviceType_name,
        };
        let result = await API.graphql(
          graphqlOperation(UpdateServiceType, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.UpdateServiceType);
        if (ResultObject.status == "SUCCESS") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.AddServiceTypedialogEmit((this.Toggle = 2));
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    validateMethod() {
      if (this.$refs.form.validate()) {
        if (this.StoreObj.action == "CREATE") {
          this.createServiceType();
        } else if (this.StoreObj.action == "EDIT") {
          this.editServiceType();
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fill all the required details",
        };
      }
    },
    async createServiceType() {
      this.isLoading = false;
      try {
        var inputParams = {
          creator_user_id: this.$store.getters.get_current_user_details.user_id,
          service_type_name: this.serviceType_name,
          organization_id: this.manufacturer,
        };
        let result = await API.graphql(
          graphqlOperation(CreateServiceType, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.CreateServiceType);
        if (ResultObject.status == "SUCCESS") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.AddServiceTypedialogEmit((this.Toggle = 2));
        }
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    AddServiceTypedialogEmit(Toggle) {
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
