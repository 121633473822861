import { ListBMSManufacturers } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllManufacturer = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      GetAllManufacturerList: [],
    };
  },
  methods: {
    async GetAllBMSMenufacturerMethod(organization_status) {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(ListBMSManufacturers, {
            input: {
              organization_status:
                organization_status == undefined
                  ? "ACTIVE"
                  : organization_status,
            },
          })
        );
        this.GetAllManufacturerList = JSON.parse(
          result.data.ListBMSManufacturers
        ).data.items;
        if (this.$route.name != "Manufacturer") {
          this.GetAllManufacturerList.unshift({
            organization_name: "ALL",
            organization_id: null,
          });
        }
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.GetAllManufacturerList = [];
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
